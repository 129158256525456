/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Link } from 'gatsby';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { userSelector } from '@redux/selectors';
import { createFlashMessage } from '@components/Flash/redux';
import { FlashMessages } from '@components/Flash/redux/flash-messages';
import { executeGA } from '../../../redux/actions';
import { ChallengeWithCompletedNode, User } from '../../../redux/prop-types';

import CheckedCurriculum from '../../../assets/icons/checked-curriculum';
import LockChallenge from '../../../assets/icons/lock-challenge';
import UncheckedCurriculum from '../../../assets/icons/unchecked-curriculum';
import News from '../../../assets/icons/news';

import Terminal from '../../../assets/icons/terminal';
import { AccessLevel } from '../../../utils/enums/access-levels';

import './challenges.css';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ executeGA, createFlashMessage }, dispatch);

console.log('mapDispatchToProps', mapDispatchToProps);

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

function RenderChallenge({
  challenge,
  challengesWithCompleted,
  user,
  index
}: {
  challenge: ChallengeWithCompletedNode;
  challengesWithCompleted: ChallengeWithCompletedNode[];
  user: User;
  index: number;
}): JSX.Element {
  return (
    <>
      <div className='challenge-link-items'>
        <div className='challenge-link-title'>
          {renderIconAndTexto(challenge.title)}
        </div>
      </div>
      {isCompleteChallenge(challengesWithCompleted, index, user.access)}
    </>
  );
}

interface Challenges {
  readonly challengesWithCompleted: ChallengeWithCompletedNode[];
  readonly user: User;
  readonly createFlashMessage: typeof createFlashMessage;
}

function Challenges({
  challengesWithCompleted,
  user,
  createFlashMessage
}: Challenges): JSX.Element {
  const isContentBlocked = useMemo(
    () => user.isBlockedByExpiration,
    [user.isBlockedByExpiration]
  );

  const handleFlashMessage = () => {
    if (isContentBlocked && user.isBlockedByExpiration) {
      createFlashMessage({
        message: FlashMessages.UserBlockedByExpiration,
        type: 'danger'
      });
      return;
    }
  };

  return (
    <ul onClick={handleFlashMessage} className={`map-challenges-ul`}>
      {/* challenges */}
      {challengesWithCompleted.map((challenge, index) => (
        /* challenge */
        <li
          className={`challenger ${verifyChallengeEnableContainer(
            challengesWithCompleted,
            index,
            user
          )}`}
          id={challenge.dashedName}
          key={'map-challenge' + challenge.fields.slug}
        >
          {isContentBlocked ? (
            <div className={'challenge-link-disabled'}>
              <RenderChallenge
                challenge={challenge}
                challengesWithCompleted={challengesWithCompleted}
                index={index}
                user={user}
              />
            </div>
          ) : (
            <Link
              className={verifyChallengeEnable(
                challengesWithCompleted,
                index,
                user
              )}
              to={challenge.fields.slug}
            >
              <RenderChallenge
                challenge={challenge}
                challengesWithCompleted={challengesWithCompleted}
                index={index}
                user={user}
              />
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
}

function renderIconAndTexto(title: string): JSX.Element {
  let icon = null;
  let text = title;

  if (title.includes('📄')) {
    icon = <Terminal />;
    text = title.replace('📄 ', '');
    // these are different icon
  } else if (title.includes('▶️') || title.includes('▶')) {
    icon = <News />;
    text = title.replace('▶️ ', '');
    text = text.replace('▶ ', '');
  } else if (title.includes('📘')) {
    icon = <News />;
    text = title.replace('📘 ', '');
  }

  return (
    <>
      {icon}
      <p>{text}</p>
    </>
  );
}

function verifyChallengeEnableContainer(
  challenges: ChallengeWithCompletedNode[],
  index: number,
  user: User
) {
  if (user.isBlockedByExpiration) return 'challenger-disabled';

  switch (user.access) {
    case AccessLevel.COMPLETE:
      return verifyAccessComplete(challenges, index);
    case AccessLevel.LIMITED:
      return verifyAccessLimited(challenges, index);
    case AccessLevel.BLOCKED:
      return verifyAccessBlocked(challenges, index);
    default:
      return verifyAccessComplete(challenges, index);
  }
}

function verifyAccessLimited(
  challenges: ChallengeWithCompletedNode[],
  index: number
) {
  if (index === 0) {
    if (challenges[index].isCompleted) {
      return '';
    } else if (challenges[index].order > 5) {
      return 'challenger-disabled';
    } else {
      return '';
    }
  }
  if (challenges[index - 1].isCompleted) {
    if (challenges[index].isCompleted) {
      return '';
    } else if (challenges[index].order > 5) {
      return 'challenger-disabled';
    } else {
      return '';
    }
  } else {
    return 'challenger-disabled';
  }
}

function verifyAccessBlocked(
  challenges: ChallengeWithCompletedNode[],
  index: number
) {
  if (challenges[index].isCompleted) {
    return '';
  } else {
    return 'challenger-disabled';
  }
}

function verifyAccessComplete(
  challenges: ChallengeWithCompletedNode[],
  index: number
) {
  if (index === 0) {
    return '';
  }
  if (challenges[index - 1].isCompleted) {
    return '';
  } else {
    return 'challenger-disabled';
  }
}

function verifyChallengeEnable(
  challenges: ChallengeWithCompletedNode[],
  index: number,
  user: User
) {
  if (user.isBlockedByExpiration) return 'challenge-link-disabled';

  if (index === 0) {
    if (challenges[index].isCompleted) {
      return 'challenge-link-completed';
    } else if (user.access === 'blocked') {
      return 'challenge-link-disabled';
    } else {
      return 'challenge-link';
    }
  }

  if (challenges[index - 1].isCompleted) {
    if (challenges[index].isCompleted) {
      return 'challenge-link-completed';
    } else if (user.access === 'blocked') {
      return 'challenge-link-disabled';
    } else {
      return 'challenge-link';
    }
  } else {
    return 'challenge-link-disabled';
  }
}

function isCompleteChallenge(
  challenges: ChallengeWithCompletedNode[],
  index: number,
  access: string
) {
  if (index === 0) {
    if (challenges[index].isCompleted) {
      return <CheckedCurriculum className='challenge-icon' />;
    } else if (access === 'blocked') {
      return <LockChallenge className='challenge-icon' />;
    } else {
      return <UncheckedCurriculum className='challenge-icon' />;
    }
  } else if (challenges[index - 1].isCompleted) {
    if (challenges[index].isCompleted) {
      return <CheckedCurriculum className='challenge-icon' />;
    } else if (access == 'blocked') {
      return <LockChallenge className='challenge-icon' />;
    } else {
      return <UncheckedCurriculum className='challenge-icon' />;
    }
  } else {
    return <LockChallenge className='challenge-icon' />;
  }
}

Challenges.displayName = 'Challenges';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Challenges));
